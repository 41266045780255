import { FunctionComponent, useCallback } from "react";
import "./css/FrameComponent.css";

export const FrameComponent: FunctionComponent = () => {
    const onHomeTextClick = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='Home']");
        if (anchor) {
          anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, []);
      const onTeamTextClick = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='ourTeam']");
        if (anchor) {
          anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, []);
      const onAboutClick = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='about']");
        if (anchor) {
          anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, []);
      const onRoadMapClick = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='roadMap']");
        if (anchor) {
          anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, []);
      const onFAQClick = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='FAQ']");
        if (anchor) {
          anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, []);
      const onConnectContainerClick = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='Connect']");
        if (anchor) {
          anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, []);
      
  return (
    
    <main>
      
    <div className="frame-div24">
    <div className="pexels-scott-webb-1022922-remo-div2">
        <img
            className="pexels-scott-webb-1022922-remo-icon2"
            alt=""
            src="potflip.png"
            
          />
        </div>
      <div className="frame-div25">
        <div className="frame-div26">


        <div className="frame-div27" >
            
            <div className="rectangle-div1">
              <img className="logotrue-icon1" alt="" src="dark11.svg " />
            </div>
            <div className="frame-div28">
              <div className="frame-div29">
                <div className="home-div1" onClick={onHomeTextClick}>Home</div>
                <div className="home-div1" onClick={onAboutClick}>About Us</div>
                <a className="frame-a"  style={{ textDecoration:"none",color:"inherit"}} href="https://uploads.strikinglycdn.com/files/06f53d23-65cd-493d-ad56-2d654063a8d7/PhytaZenX%20-%20NFT%20Lightpaper%20-%20Version%201.1%20-%2026Sep2022.pdf?id=3941483" target="_blank" rel="noreferrer">   <div className="home-div1" > Lightpaper</div> </a>
                <div className="home-div1" onClick={onRoadMapClick}>Road Map</div>
              </div>
              <div className="community-div4">
                <div className="community-div5">
                  <div className="home-div1" onClick={onTeamTextClick}>Team</div>
                  <img className="frame-icon5" alt="" />
                </div>
              </div>
              <div className="community-div4">
                <div className="community-div5">
                  <div className="home-div1" onClick={onFAQClick}>FAQs</div>
                  <img className="frame-icon5" alt="" />
                </div>
              </div>
            </div>
            <div className="frame-div30">
            <a className="frame-a"  style={{ textDecoration:"none",color:"inherit"}} href="https://discord.com/invite/8YqW2kDkpE" target="_blank" rel="noreferrer">
              <div className="component-4-div">
                <img className="frame-icon75" alt="" src="frame20.svg" />
                <div className="join-div1"><b>Discord</b></div>
              </div>
              </a>
              <div className="component-5-div1" style={{cursor:"pointer"}} onClick={onConnectContainerClick}>
                <img className="frame-icon7" alt="" src="connect.svg" />
                <div className="join-div1" style={{fontSize:"16px"}}><b> Buy NFT</b></div>
              </div>
            </div>
            <img className="ellipse-icon9" alt="" src="ellipse-1710.svg" />
          </div>



          <div className="frame-div31">
            <div className="frame-div32">
              <div className="frame-div33">
                <div className="frame-div34">
                  <div className="home-div1" data-scroll-to='Home'>Accelerating the Growth of Natural Botanical Medicine to Solve the Healthcare Crisis</div>
           
                </div>
                <div className="naruto-is-a-japanese-manga-ser2">
               
                <ul >
  <li style={{lineHeight:"24px",paddingBottom:"2px",fontSize:"22px"}}>PhytaZenX uses blockchain technology and herbal medicine to solve the healthcare crisis</li>
  <li style={{lineHeight:"24px",paddingBottom:"2px",fontSize:"22px"}}>PhytaZenX opens up an untapped $300B market to all of us</li>
  <li style={{lineHeight:"24px",fontSize:"22px"}}>PhytaZenX is a utility NFT for our VIP membership club</li>
</ul>
                </div>
              </div>
              <div className="home-div10" ><span  className="globtext">Join our global community and be a part of the healthcare revolution.</span></div>
              <div className="button-states-div1" onClick={onConnectContainerClick} >
          

                <div className="home-div1" >Buy Now</div>
              </div>
            </div>
             <img className="image-2-icon" alt="" src="phythq.gif" style={{border: "2px solid #fff",borderRadius: "8.84px"}} /> 
             {/* <iframe width="420" height="315"
src="https://www.youtube.com/embed/VQ1pfnb_Hlk">
</iframe>  */}
{/* <iframe width="489" height="476" src="https://www.youtube.com/embed/CQWFc9FJGVg" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe> */}
          </div>
          <div  className="iframediv">
            
          <iframe   src="https://www.youtube.com/embed/CQWFc9FJGVg" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{marginLeft:"50wh"}} data-scroll-to='about'></iframe>
          
          </div>
        </div>
        <div className="frame-div35">
          <div className="frame-div36">
            <img className="vector-icon2" alt="" src="vector20.svg" />
            <b className="problem1" >The Problem</b>
          </div>
          <div className="frame-div37">
           
            <div className="cant-afford-to-miss-the-3001">
              <p className="cant-afford-to1" >
              Let’s face it, mainstream drugs do not help patients recover from chronic disease. Most prescription drugs in the market today are ineffective, filled with side effects, and too expensive, causing a global healthcare crisis. Did you know that thousands of companies all over the world produce natural herbal medicines that reverse disease with no side effects, and are faster and cheaper to develop in clinical trials? Unfortunately patients never find out about these treatments because herbal medicine companies are neglected and do not have equal access to resources for clinical trials and commercialization.
        
 


              </p>
              <p className="cant-afford-to1">&nbsp;</p>
              <p className="hurry-up-only1">
               
              </p>
            </div>
          </div>
        </div>
        <div className="pexels-scott-webb-1022922-remo-div1">
          <img
            className="pexels-scott-webb-1022922-remo-icon1"
            alt=""
            src="pexelsscottwebb1022922removebgpreview-1@2x.png"
          />
        </div>
        
      </div>
      <div className="frame-div38">
     
        <div className="frame-div39">
        
          <div className="frame-div40">
            <div className="frame-div41">
              <b className="problem2">Our Solution</b>
              <div className="our-mission-div1"></div>
            </div>
            <img className="vector-icon3" alt="" src="vector30.svg" />
          </div>
          
          <div className="naruto-is-a-japanese-manga-ser3">
          Let’s change this. Better treatment options already exist – we just need to bring them to market. Introducing PhytaZenX (“fai-tuh-zen-eks”), a digital platform to accelerate the growth of herbal medicine in mainstream healthcare. Our digital marketplace uses blockchain technology to provide resources for botanical medicine clinical trials, approval and commercialization. 
          </div>
          <div className="naruto-is-a-japanese-manga-ser3">
          We want you to join us on this journey so PhytaZenX is forming a VIP membership club by launching a utility NFT. Our NFT will give you VIP access to benefits and perks for products and services on our digital platform. Be the first to get access to discounts, presales, voting rights, airdrops, new NFT collections, and much more! As an NFT holder, you will have the opportunity to influence herbal medicine research and drug development for diseases that you care about the most.
          </div>
          <div className="naruto-is-a-japanese-manga-ser3">
          Our digital platform will accelerate the development and approval of botanical medicine to transform and drive explosive growth in the healthcare industry. We make socially responsible medicine to solve the healthcare crisis by providing better treatments for patients worldwide. Join us and spread the word. The healthcare revolution is here.
          </div>
        </div>
        
      
      </div>
      
    </div>
    </main>
  );
};
export default FrameComponent;
