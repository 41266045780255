import { FunctionComponent ,useCallback} from "react";
import styles from "./css/V222ndFlow.module.css";
import { useNavigate } from "react-router-dom";
const V222ndFlow: FunctionComponent = () => {
  const onConnectContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='Connect']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const navigate = useNavigate();

  const tclick = () => {
      navigate("/tc");
  }
  return (
    <div className={styles.v222ndFlowDiv}>
      <div className={styles.frameDiv}>
        <div className={styles.frameDiv1}>
          <div className={styles.frameDiv2}>
            <div className={styles.frameDiv3}>
              <img className={styles.vectorIcon} alt="" src="../vector100.svg" />
              <b className={styles.roadmapB} data-scroll-to='roadMap'>Road Map</b>
            </div>
          
          </div>
          <div className={styles.frameDiv4}>
            <div className={styles.roadMapDiv}>
              <div className={styles.frameDiv5}>
                <b className={styles.b}>Q3 2022</b>
              </div>
              <div className={styles.frameDiv6}>
                <b className={styles.weAllHaveThePowerToChang } style={{marginLeft:"3%"}}>&#9679; Community Building <br/>&#9679; NFT Development<br/>&#9679; NFT Design Release<br/>&#9679; NFT Sale Schedule Release<br/> </b>
               
              </div>
            </div>
            <div className={styles.roadMapDiv}>
              <div className={styles.frameDiv5}>
                <b className={styles.b}>Q4 2022</b>
              </div>
              <div className={styles.frameDiv6}>
              <b className={styles.weAllHaveThePowerToChang} style={{marginLeft:"3%"}}>
              &#9679; NFT Sale <br/>&#9679; NFT Community Events<br/>&#9679; NFT Secondary Market Listing<br/> &#9679; Product Development<br/>
              </b>
              </div>
            </div>
            <div className={styles.roadMapDiv}>
              <div className={styles.frameDiv5}>
                <b className={styles.b}>Q1 2023</b>
              </div>
              <div className={styles.frameDiv6}>
              <b className={styles.weAllHaveThePowerToChang} style={{marginLeft:"3%"}}>
              &#9679; Product Development<br/> &#9679; Develop Proof of Concept <br/>&#9679; NFT Community Events<br/>&#9679; New NFT  Announcemnts
              </b>
              </div>
            </div>
            <div className={styles.roadMapDiv}>
              <div className={styles.frameDiv5}>
                <b className={styles.b}>Q2 2023</b>
              </div>
              <div className={styles.frameDiv6}>
              <b className={styles.weAllHaveThePowerToChang} style={{marginLeft:"3%"}}>
              &#9679; Product Development<br/>&#9679; NFT Community Events<br/>&#9679; Launch MVP<br/>&#9679; Pilot Test Launch<br/>
              </b>
              </div>
            </div>
           
          
          </div>
        </div>
      </div>
      <div className={styles.frameDiv12}>
        <div className={styles.frameDiv13}>
         
          <b className={styles.ourTeamB} data-scroll-to='ourTeam'>Our Team</b>
          <img className={styles.vectorIcon2} alt="" src="../vector101.svg" />
        </div>
        <div className={styles.frameDiv14}>
      
          <div className={styles.frameDiv15}>
            <div className={styles.component3Div}>
              <div className={styles.rectangleDiv} />
              <div className={styles.frameDiv16}>
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="../jen1-rounded.png"
                />
                <div className={styles.frameDiv17}>
                  <div className={styles.mikeAndersonDiv}>Jennifer Wong</div>
                  <div
                    className={styles.founderCEO}
                  >{`Founder &`} <br/>{`Chief Executive Officer`}
                      
          </div>
          <span style={{fontSize:"18px",textAlign:"left",padding:"0",marginTop:"-17px"}}>     <ul   >
  <li style={{paddingBottom:"0.3em"}}>&gt;10 years of experience in business strategy, market research, competitive intelligence, marketing and sales for the healthcare and biopharmaceutical drug industries</li>
  <li style={{paddingBottom:"0.3em"}}>Worked closely with top global biotech and pharmaceutical companies, as well as botanical companies in the US</li>
  <li style={{paddingBottom:"0.3em"}}>Bachelor’s degree in Integrative Biology from UC Berkeley</li>

</ul></span>
               
                </div>
              </div>
            </div>
            <div className={styles.component4Div}>
              <div className={styles.rectangleDiv} />
              <div className={styles.frameDiv16}>
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="../sam1-modified.png"
                />
                <div className={styles.frameDiv17}>
                  <div className={styles.mikeAndersonDiv}>Sam Nanayakkara</div>
                  <div
                    className={styles.founderCEO}
                  >{`Technology &`} <br/> {`Strategy`}</div>
                   <span className="teamtext" style={{fontSize:"18px",textAlign:"left",padding:"0",marginTop:"-17px"}}>     <ul  >
                   <li style={{paddingBottom:"0.3em"}}>Serial entrepreneur with 30 years of experience in technology leadership</li>
                   <li style={{paddingBottom:"0.3em"}}>Specialize in Web2 + Web3, analytics, marketing, operations &amp; supply chain for the technology, finance, healthcare, aerospace, manufacturing and insurance industries</li>
  <li style={{paddingBottom:"0.3em"}}>Before age 30, successfully started 6 companies in finance &amp; real estate industries &amp; exited in 2008</li>
 
<li style={{paddingBottom:"0.3em"}}>Leadership roles at UTC Supply Chain, Numotion, AeroMed, PE-backed companies, and startups</li>
</ul></span>
                </div>
              </div>
            </div>

          
            
            
            
          </div>
          
          <div className={styles.frameDiv15}>
            
            <div className={styles.component2Div}>
              <div className={styles.rectangleDiv} />
              <div className={styles.frameDiv16}>
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="../ken1-rounded.png"
                  
                 
                />
                <div className={styles.frameDiv17}>
                  <div className={styles.mikeAndersonDiv}>Kenneth Kayser</div>
                  <div
                    className={styles.founderCEO}
                  >{`Finance &`}<br/>  {`Fintech Adviser`}</div>
                   <span style={{fontSize:"18px",textAlign:"left",padding:"0",marginTop:"-17px"}}>     <ul  >
                   <li style={{paddingBottom:"0.3em"}}>20 years of experience in finance and fintech industries</li>
                   <li style={{paddingBottom:"0.3em"}}>Specialize in product dev, UX/UI design, commercialization, business dev, and investment financing</li>
  <li style={{paddingBottom:"0.3em"}}>Co-founder of tech startups such as Envel Bank, CarScan, and Young Engineers, which are now part of his venture portfolio</li>
 
<li style={{paddingBottom:"0.3em"}}>Leadership roles at Barclays Africa (Absa), Nedbank &amp; Standard Bank</li>
</ul></span>
                </div>
              </div>
            </div>
            <div className={styles.component2Div}>
              <div className={styles.rectangleDiv} />
              <div className={styles.frameDiv16}>
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="../jean1-roundedpng.png"
                />
                <div className={styles.frameDiv17}>
                  <div className={styles.mikeAndersonDiv}>Dr. Jean Wang</div>
                  <div
                    className={styles.founderCEO}
                  >{`Botanical Medicine &`} <br/>{` Regulatory Adviser`}</div>
                   <span style={{fontSize:"18px",textAlign:"left",padding:"0",marginTop:"-17px"}}>     <ul  >
                   <li style={{paddingBottom:"0.3em"}}>30 years of experience in natural medicine, pharmaceuticals, life sciences, IT and data analytics</li>
                   <li style={{paddingBottom:"0.3em"}}>Developed pharmaceuticals and life science products at Takeda &amp; Bayer</li>
  <li style={{paddingBottom:"0.3em"}}>Consult herbal medicine companies on clinical trials for US FDA approval    </li>
 
<li style={{paddingBottom:"0.3em"}}>Healthcare and life sciences solution design, data analytics and AI at IBM</li>
<li style={{paddingBottom:"0.3em"}}>PhD in Natural Product Chemistry and Pharmacognosy </li>
</ul></span>
                </div>
              </div>
            </div>
            <div className={styles.component2Div}>
              <div className={styles.rectangleDiv} />
              <div className={styles.frameDiv16}>
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="../pete1-rounded.png"
                />
                <div className={styles.frameDiv17}>
                  <div className={styles.mikeAndersonDiv}>Peter Leighton</div>
                  <div
                    className={styles.founderCEO}
                  >{`Commercial &`} <br/>{` Marketing Adviser`}</div>
                  <span style={{fontSize:"18px",textAlign:"left",padding:"0",marginTop:"-17px"}}>     <ul  >
                   <li style={{paddingBottom:"0.3em"}}>30 years of experience in consumer products, nutrition, functional foods, nutraceuticals and biotech</li>
                   <li style={{paddingBottom:"0.3em"}}>Led marketing &amp; commercialization strategies for hundreds of products</li>
  <li style={{paddingBottom:"0.3em"}}>CEO at Advana Science, VP Marketing &amp; Product Dev at Natrol (NTOL), VP at Galileo Laboratories, SVP Marketing at Weider Nutrition (WNI) and Director of Marketing at InterHealth Nutraceuticals
</li>
 

</ul></span>
                </div>
              </div>
            </div>
            
        </div>


          
        </div>
      </div>
      <div className={styles.frameDiv22}>
        <div className={styles.frameDiv23}>
          <img className={styles.vectorIcon1} alt="" src="../vector102.svg" />
          <b className={styles.ourTeamB} data-scroll-to='FAQ'>FAQs</b>
        </div>
        <div className={styles.frameDiv24}>
          <div className={styles.frequentlyAksedQuestions}>
            Frequently Asked Questions
          </div>
          <div className={styles.frameDiv25}>
          <div className={styles.fAQGlassDiv}>
              <div className={styles.frameDiv26}>
                <div className={styles.howManyNFTsWeveGot}>
                What is botanical or herbal medicine?
                </div>
              </div>
              <div className={styles.frameDiv27}>
                <div
                  className={styles.weAllHaveThePowerToChang}
                >{`Herbal medicines contain active ingredients made from plant parts, such as leaves, roots or flowers. The usage of botanical herbs for treating disease is an ancient science. These medicines are made using a variety of plants and natural extracts that have been used all over the world for thousands of years. One of the biggest advantages of natural medicine is that botanical herbs do not have adverse side effects commonly seen in modern medicine.`}</div>
              </div>
            </div>
            <div className={styles.fAQGlassDiv}>
              <div className={styles.frameDiv26}>
                <div className={styles.howManyNFTsWeveGot}>
                What is a utility NFT?
                </div>
              </div>
              <div className={styles.frameDiv27}>
                <div
                  className={styles.weAllHaveThePowerToChang}
                >{`A non-fungible token (NFT) represents ownership of something digital that is unique and not
                interchangeable. Examples of popular NFTs include digital art, music, and photos. A utility NFT, on the
                other hand, is an NFT that has a real-world use case beyond just being a unique digital asset. A utility
                NFT grants their owner access to real-world benefits, privileges, rights, or rewards.`}</div>
              </div>
            </div>
            <div className={styles.fAQGlassDiv}>
              <div className={styles.frameDiv26}>
                <div className={styles.howManyNFTsWeveGot}>
                How do I join your VIP membership club?
                </div>
              </div>
              <div className={styles.frameDiv27}>
                <div
                  className={styles.weAllHaveThePowerToChang}
                >{`	 Step 1: Buy an NFT from this website to become an official member of our VIP membership club.
                 `}<br/>
                {`	
               Step 2: Join our community on Discord and our moderators will teach you how to get access to perks and benefits for future products and services on our digital platform. `}</div>
              </div>
            </div>
          
            
           
            
          
          </div>
        </div>
      </div>
      <div className={styles.frameDiv28}>
        <div className={styles.frameDiv29}>
          <div className={styles.frameDiv30}>
            <div className={styles.frameDiv31}>
              <div className={styles.weAllHaveThePowerToChang2}>
                <p className={styles.joinOurAwesomeCommunity}>
                  <span className={styles.joinOurAwesome}>
                    Join Our Global Community
                  </span>
                  <span>{` `}</span>
                </p>
                <p className={styles.getEarlyToTheParty}>
                  <span>{``}</span>
                </p>
              </div>
            </div>
            <div className={styles.frameDiv32}>
              <div className={styles.frameDiv33}>
              <a className="frame-a"  style={{ textDecoration:"none",color:"inherit"}} href="https://discord.com/invite/8YqW2kDkpE" target="_blank" rel="noreferrer">
              <div className={styles.outLinedButtonStates1}>
                  <img
                    className={styles.frameIcon}
                    alt=""
                    src="../frame51.svg"
                  />
                  <div className={styles.whitelistDiv}>Join Now</div>
                </div>
                </a>
              </div>
              <div className={styles.frameDiv33}>
                
              <div className={styles.outLinedButtonStates1}  onClick={onConnectContainerClick} >
              <img className="frame-icon7" alt="" src="connect.svg" />
                  <div className={styles.whitelistDiv} style={{cursor:"pointer"}}>Buy NFT</div>
                </div>
              </div>
            </div>
          </div>
          <img
            className={styles.hangingLeafIcon}
            alt=""
            src="../hanging-leaf@2x.png"
          />
        </div>
        <div className={styles.frameDiv35}>
          <div className={styles.frameDiv36}>
            <div className={styles.frameDiv37}>
              <div className={styles.frameDiv38}>
                <div className={styles.rectangleDiv3}>
                  <img
                    className={styles.logoTrueIcon}
                    alt=""
                    src="../dark11.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv39}>
              <div className={styles.aliquaIdFugiatNostrudIrure}>
              
              </div>
            </div>
          </div>
          <div className={styles.frameDiv40}>
            <div />
          </div>
        </div>
        <div className={styles.frameDiv41}>
         
        
        </div>
      </div>
      <div className={styles.frameDiv42}>
        <div className={styles.frameDiv43}>
          <div className={styles.frameDiv44}>
            <div className={styles.b} style={{fontSize:"17.2px"}}>Copyright 2022 PhytaZenX</div>
          </div>
          <div className={styles.frameDiv45}>
            <div className={styles.b}><span onClick={tclick} style={{textDecoration:"none",color:"white",cursor:"pointer",fontSize:"18px"}}>{`Terms & Conditions`}</span></div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default V222ndFlow;
