import { FunctionComponent } from "react";
import styles from "./css/Privacyfixed.module.css";

const Privacyfixed: FunctionComponent = () => {
  return (
   
    <div className={styles.privacyfixedDiv}>
      
      <img className={styles.ellipseIcon} alt="" src="../ellipse-17.svg" />
      <div className={styles.frameDiv3} style={{marginTop:"10vh",marginBottom:"10vh"}}>
        <img className={styles.vectorIcon} alt="" src="../vector.svg" />
        <b className={styles.disclaimerB} >Disclaimer</b>
      </div>
      
      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>NFT Ownership</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          The purchaser of the NFT understands and agrees that it is acquiring title to it solely for personal, non-commercial purposes, and
they shall have no authority, right, title or interest in, to or in connection with the copyrights, trademarks or other intellectual
property (IP) or proprietary rights in and to the NFT, all of which remain solely with the original creator and seller. The NFT
purchaser also understands and agrees that they have no rights of reproduction or commercial exploitation of the NFT or the
copyrights, trademarks or other IP or proprietary rights in and to the NFT, other than the right to sell, trade, donate, give away, or
transfer the NFT. If at any time you sell, trade, donate, give away, or transfer the NFT to a new owner, title to the NFT shall be
transferred to the new owner according to the terms hereof, and you will have no further rights in or to the NFT.
          </p>
         
        </div>
      </div>
      <img className={styles.ellipseIcon1} alt="" src="../ellipse-18.svg" />
      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>Intellectual Property</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          PhytaZenX intellectual property, including but not limited to its brand, characters, story and developments, is solely owned by its
creators. 

          </p>
          
       
        </div>
      </div>
     
      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>Awareness of Risks</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          You warrant that you understand NFTs are inherently risky and speculative. NFTs are unregulated and in an early stage of
development with experimental software and business models. The NFT industry has limited government protection, high price
volatility, strong potential for inadequate documentation, and a high risk of fraud. You should only purchase NFTs if you have
strong technical knowledge and understand the specifics of the offering. Careful due diligence should be undertaken on the any
NFT project. You understand that your NFT purchase may not result in a usable or valuable NFT and you may lose the entire
value of your purchase. You are encouraged to consider the advice of and warnings about the significant risks of the NFT sector. 

          </p>
          
       
        </div>
      </div>

      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>Experimental Technologies</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          Transactions, including but not limited to primary sales, secondary market sales, listings, offers, bids, acceptances, and other
operations via the Website use experimental smart contract and blockchain technology, including NFTs, cryptocurrencies,
consensus algorithms, and decentralized networks or systems. Holders acknowledge and agree that such technologies are
experimental, speculative, and inherently risky. Holders acknowledge and agree that the NFTs may be subject to bugs,
malfunctions, timing errors, hacking, theft, or changes to the Solana blockchain protocol rules, which can adversely affect the
smart contracts and may expose you to a risk of total loss, forfeiture of your digital currency or NFTs, or lost opportunities to buy
or sell NFTs. The PhytaZenX team assumes no liability or responsibility for smart contract or related failures, risks, or
uncertainties. 

          </p>
         
        </div>
      </div>

      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>Limitations of Liability for Gas Fees, Failed Transactions and Smart Contract Bugs</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          Participants who mint PhytaZenX NFTs agree to hold the Project Team harmless for losses incurred as a consequence of
minting an NFT. Potential losses include gas fees for failed transactions and extra gas fees charged or the loss of any NFT due
to website or smart contract bugs.

          </p>
         
        </div>
      </div>



      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>No Guarantees or Future Promises</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          While the PhytaZenX team has released a roadmap on future project development plans, we cannot guarantee every item
outlined will be executed as the project evolves. You agree that your NFT purchase from our initial NFT launch is all you are
guaranteed to receive with your purchase through primary or secondary channels. Any future benefits are ancillary and not to be
taken into consideration with your purchase. You agree that you are not relying on future commitments by the PhytaZenX team in
participating in our NFT launch.
          </p>
         
        </div>
      </div>


      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>No Guarantees of Value</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          PhytaZenX NFTs were created to give NFT Holders access to our VIP membership club, not to serve as investments. We make
no promise or guarantee that these NFTs will retain monetary value in fiat, cash or cryptocurrency. 

          </p>
         
        </div>
      </div>


      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>Taxes and Bonuses or Rewards</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          Each Holder is solely and entirely responsible for all Federal or State tax liabilities which may arise, be imposed, or enforced by
the holder's country of residence as a result of minting or reselling PhytaZenX NFTs. In addition, the PhytaZenX team may
distribute bonuses or awards to Holders. It is the Holders responsibility to stay informed and comply with their country of
residence's laws and requirements. The PhytaZenX team is not responsible for Holders who break any applicable laws.

          </p>
         
        </div>
      </div>



      <div className={styles.frameDiv4}>
        <div className={styles.nFTOwnershipDiv}>Class Action Waiver, Jurisdiction and Choice of Law</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          You agree to waive any class action status, and any legal dispute around the PhytaZenX project which you may choose to bring
can only be done on an individual basis.
          </p>
         
        </div>
      </div>



      <div className={styles.frameDiv4} >
        <div className={styles.nFTOwnershipDiv}>Disclaimers Subject to Change</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          The sale or use of NFTs or virtual tokens could be prohibited under applicable securities law. Existing regulations could be
applied or new regulations could be enacted, affecting blockchain technology applications and NFTs so that any aspect of NFT
projects could be negatively affected, requiring its modification or discontinuance and potentially resulting in the loss of tokens or
token value. In order to be compliant with new practices, the PhytaZenX team reserves the right to adapt and adjust disclaimers,
practices, promises and road maps at any time.
          </p>
         
        </div>
      </div>

      <div className={styles.frameDiv4} style={{marginBottom:"10vh"}}>
        <div className={styles.nFTOwnershipDiv}>Contact Us</div>
        <div className={styles.phytaZenXIntellectualPropert}>
          <p className={styles.cantAffordTo}>
          For further assistance or questions, please reach out to us on  Discord: <a   style={{ textDecoration:"none",color:"white" }} href="https://discord.com/invite/8YqW2kDkpE" target="_blank" rel="noreferrer">click here</a>

          </p>
         
        </div>
      </div>



      

     
      
      
      <img className={styles.ellipseIcon2} alt="" src="../ellipse-22.svg" />
      
     
    </div>
   
  );
};

export default Privacyfixed;
